/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
html, body {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}
body { 
    font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #222;
  background-color: #f7f7f7;
}

.table {
    margin-top: 2em;
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    box-shadow: 2px 2px 2px 0px rgba(171,171,171,0.5), -2px -2px 2px 0px rgba(171,171,171,0.5);
  }

  .table td {
    box-shadow: -2px -2px 2px -2px rgba(171,171,171,0.5),2px 2px 2px -2px rgba(171,171,171,0.5);
    justify-content:center;
    text-align: center !important;
  }

  .table td:first-child {
    box-shadow: -2px 2px 2px -2px rgba(171,171,171,0.5),2px 2px 2px -2px rgba(171,171,171,0.5);
  }

  .table th {
    font-size: 16px;
    font-weight: bold;
    color: black;
    box-shadow: 3px 3px 2px 0px rgba(171,171,171,0.5);
  } 

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 0px !important;
  }

  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 0px !important;
  }

  .table th:first-child {
    box-shadow: 3px 2px 2px 0px rgba(171,171,171,0.5), -3px 2px 2px 0px rgba(171,171,171,0.5);
  }

  .table .mat-row:hover {
    cursor: pointer;
    font-weight: bold;
    transform:  translateY(8px);
    box-shadow: 3px 3px 1px 1px rgba(171,171,171,0.5), -3px -3px 1px 1px rgba(171,171,171,0.5);
  }

  .table tr.mat-footer-row {
    font-weight: bold;
  }

  .filter-field {
    margin-right: 50pt;
    margin-top: 25pt;
  }

  mat-action-row.filter-row {
    /* place-content: space-between; */
    box-shadow: 0px -1px 0px 0px rgba(171,171,171,0.5)
  }

  .main-container {
    max-width: 85em;
  }

  pre {
    display: block;
    font-family: Arial;
    white-space: pre;
    overflow:auto;
    white-space: pre-wrap;
  }

  .mat-expansion-panel {
    box-shadow: 3px 3px 2px 0px rgba(171,171,171,0.5), -3px -3px 2px 0px rgba(171,171,171,0.5) !important;
  }

  .my-custom-snackbar {
    position: absolute;
    bottom: 80pt;
  }

  .hint {
    display: inline-block;
     display: inline;
      color:#0075bf;
  }

  .hint:hover {
    cursor: help;
  }

  .mat-tooltip {
    font-size: 15px;
  }

  .export-button {
    margin: 0pt 5pt;
  }

  .export-button:hover {
    background: gray;
    border-radius: 25px;
    cursor: pointer;
  }

  /* Responsive Styles */
  @media screen and (max-width: 767px) {

    .subtitle-containter {
      font-size: 6px;
    }

    .card {
      height: 16px;
      margin: 8px 0;
    }

  }
